import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { Fade, Box, Center, Divider, Heading } from '@chakra-ui/react';
import { useFlexSearch } from 'react-use-flexsearch';
import queryString from 'query-string';
import { RecommendationWidget } from 'exoclick-react';

import {
  BaseLoading,
  BasePagination,
  BaseScene,
  BaseSeo,
} from '@components/base';
import { TapeList } from '@components/tape';

export default function SearchPage({
  data: {
    localSearchTapes: { index, store },
  },
  location: { search },
}) {
  const { q: keyword } = queryString.parse(search);
  const [loading, setLoading] = React.useState(false);
  const totalTapes = useFlexSearch(keyword, index, store);

  const currentPage = useMemo(() => {
    const { page } = queryString.parse(search);
    return page ? +page : 1;
  }, [search]);

  const tapes = React.useMemo(() => {
    const start = (currentPage - 1) * +process.env.GATSBY_TAPES_PER_PAGE;
    return totalTapes.slice(start, start + +process.env.GATSBY_TAPES_PER_PAGE);
  }, [totalTapes, currentPage]);

  const numPages = React.useMemo(
    () => Math.ceil(totalTapes.length / process.env.GATSBY_TAPES_PER_PAGE),
    [totalTapes]
  );

  React.useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      setLoading(false);
      clearTimeout(delay);
    }, 800);
  }, [tapes]);

  const headerText = React.useMemo(() => {
    const page = currentPage > 1 ? `, Page ${currentPage}` : '';
    return `Showing ${totalTapes.length} items for "${keyword}"${page}`;
  }, [totalTapes, keyword, currentPage]);

  const handleSelectPage = React.useCallback(
    (page) => {
      setLoading(true);
      const safeKeyword = keyword.trim().replace(/ /g, '%20');
      const path =
        page > 1 ? `?q=${safeKeyword}&page=${page}` : `?q=${safeKeyword}`;

      // navigate(path);
      window.location.href = path;
    },
    [keyword]
  );

  return (
    <BaseScene as='section'>
      <Box pos='relative'>
        <Fade in={loading} unmountOnExit>
          <BaseLoading pos='absolute' w='full' paddingBlock={16} />
        </Fade>
        <Box opacity={loading ? 0 : 1}>
          <Box as='header' mb={{ base: 3, '2xs': 6 }}>
            <Heading
              as='h1'
              mb={{ base: 1, '2xs': 2 }}
              textAlign='center'
              fontSize={{ base: 'md', '2xs': 'lg' }}
            >
              {headerText}
            </Heading>
            <Divider />
          </Box>
          <TapeList tapes={tapes} tapeTitleProps={{ as: 'h2' }} />
          <Center pt={4} pb={2}>
            <RecommendationWidget zoneId={4856200} />
          </Center>
          {!!tapes.length && (
            <BasePagination
              mb={2}
              numPages={numPages}
              currentPage={currentPage}
              onSelectPage={handleSelectPage}
            />
          )}
        </Box>
      </Box>
      <Divider />
    </BaseScene>
  );
}

export function Head({ data }) {
  const { pathname } = useLocation();
  const { siteUrl, siteTitle, datePublished } = data.site.siteMetadata;

  const schemaGraphs = React.useMemo(() => {
    const pageUrl = `${siteUrl}${pathname}`;

    return [
      {
        '@type': 'WebPage',
        '@id': pageUrl,
        url: pageUrl,
        name: `Search ∘ ${siteTitle}`,
        isPartOf: {
          '@id': `${siteUrl}/#website`,
        },
        datePublished,
        dateModified: datePublished,
        breadcrumb: {
          '@id': `${pageUrl}#breadcrumb`,
        },
        inLanguage: 'en-US',
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [pageUrl],
          },
        ],
      },
    ];
  }, [pathname, siteUrl, siteTitle, datePublished]);

  return (
    <BaseSeo
      title='Search'
      description='Search Videos'
      schemaGraphs={schemaGraphs}
    />
  );
}

export const query = graphql`
  query searchQuery {
    site {
      siteMetadata {
        siteTitle: title
        siteUrl
        datePublished
      }
    }
    localSearchTapes {
      index
      store
    }
  }
`;
